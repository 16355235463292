<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Klanten voor CV's" subtitle="Beheer klantnamen t.b.v. CV's" route="/cv/clients/new" />

    <div class="bg-white shadow overflow-hidden sm:rounded-md mb-5">
      <div class="sm:grid sm:grid-cols-4 sm:items-start sm:py-5">
        <label for="search" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 px-6">
          Search
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-2">
          <div class="max-w-lg flex rounded-md shadow-sm">
            <input type="text" name="search" id="search" autocomplete="search" class="flex-1 block w-full focus:ring-orange-500 focus:border-orange-500 min-w-0 rounded sm:text-sm border-gray-300" v-model="searchQuery" v-on:keyup.enter="search">
          </div>
        </div>
        <div class="block text-sm font-medium text-gray-700">
          <button class="px-4 py-2 md:ml-2 border border-gray-300 text-sm font-medium rounded-md" @click="search">search</button>
          <button class="px-4 py-2 md:ml-2 border border-gray-300 text-sm font-medium rounded-md" @click="clear">clear</button>
        </div>
      </div>
    </div>

    <div class="bg-white shadow overflow-hidden sm:rounded-md">
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="
                shadow
                overflow-hidden
                border-b border-gray-200
                sm:rounded-lg
              "
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Name
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="c in content" :key="c.id">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="flex-shrink-0 h-10 w-10">
                          <DynamicImage
                            :source="c.Photos"
                            class="h-10 w-10 rounded-full"
                          />
                        </div>
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                            {{ c.title }}
                          </div>
                          <div class="text-sm text-gray-500">
                            {{ c.updatedAt }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      Admin
                    </td>
                    <td
                      class="
                        px-6
                        py-4
                        whitespace-nowrap
                        text-right text-sm
                        font-medium
                      "
                    >
                      <router-link
                        :to="'/cv/clients/' + c.id"
                        class="text-orange-600 hover:text-orange-900"
                        >edit</router-link
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
              
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <router-link to="/cv/clients/new" class="inline-flex justify-center w-full mt-4 py-2 px-4 mt-4  border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">Add client</router-link>
    <Pagination
      v-if="content.length > 0"
      :page="page"
      :pages="pages"
      :itemCount="itemCount"
      :pageSize="pageSize"
      @next="next"
      @previous="previous"
    />
  </main>
</template>

<script>
import ClientService from "../../../services/admin/clients/service";
import Pagination from "../../../components/Pagination";
import DynamicImage from "../../../components/DynamicImage.vue";

export default {
  name: "ClientOverview",
  components: {
    Pagination,
    DynamicImage,
  },
  data() {
    return {
      content: "",
      searchQuery: null,
      page: 1,
      pages: 0,
      itemCount: 0,
      pageSize: 8,
    };
  },
  methods: {
    fetchData() {
      ClientService.list(this.page, this.pageSize, this.searchQuery).then(
        (response) => {
          this.content = response.data.items;
          this.pages = response.data.pageCount;
          this.itemCount = response.data.itemCount;
        },(error) => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        if (error.response.status === 401)
          this.$router.push(`/auth`);
      }
      );
    },
    reset() {
      this.pages = 0;
      this.itemCount = 0;
    },
    clear() {
      this.searchQuery = null;
      this.reset();
      this.fetchData();
    },
    search() {
      this.reset();
      this.fetchData();
    },
    next() {
      this.page = this.page + 1;
      this.fetchData();
    },
    previous() {
      this.page = this.page - 1;
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
