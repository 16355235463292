import axios from "axios";
import authHeader from "../../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class ClientService {
  list(page, limit, query) {
    if (query) {
      return axios.get(API_URL + `admin/clients?page=${page}&size=${limit}&q=${query}`, { headers: authHeader() });
    } else {
      return axios.get(API_URL + `admin/clients?page=${page}&size=${limit}`, { headers: authHeader() });
    }
  }

  getAllClients() {
    return axios.get(API_URL + `admin/clients?page=1&size=2000`, { headers: authHeader() });
  }

  get(id) {
    return axios.get(API_URL + `admin/clients/${id}`, { headers: authHeader() });
  }

  save(data) {
    return axios.put(API_URL + `admin/clients/${data.id}`, { ...data }, { headers: authHeader() });
  }

  async create(formdata) {
    const { data } = await axios.post(API_URL + `admin/clients/`, {...formdata}, { headers: authHeader() });
    return data;
  }

  delete(id) {
    return axios.delete(API_URL + `admin/clients/${id}`, { headers: authHeader() });
  }
}

export default new ClientService();
